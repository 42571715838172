<template>
  <v-dialog width="700" v-model="fieldMappingDialog"
  scrollable
       persistent >
    <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn :disabled="btnDisabledDetail"     class="btn ml-1"  v-bind="attrs" v-on="on" style="background:#e27070 !important;float:right"   text dark>{{$t('Field Mapping')}}</v-btn>
    </template> -->
    <v-card class="popup add_store_popup">
      <v-card-title> <h3 class="page-title-bar">
        <i class="ico ico-system"></i>{{ $t('Field Mapping') }}
      </h3></v-card-title>
      <v-card-text >
      <v-row>
        <v-col cols="12" sm="6" class="tbl-type03" style="background-color:#001e38;color:white;text-align:center">
          {{$t('MAPPED FIELD')}}</v-col>
        <v-col cols="12" sm="6" class="tbl-type03" style="background-color:#001e38;color:white;text-align:center">
          {{$t('SELECTED FIELD')}}</v-col>
      </v-row>
      <v-row class="" v-for="item in getInitialData" :key="item.id">
        <v-col cols="12" sm="6" class="padding-0">
          <v-btn class="btnClass" style="width:100%;cursor:default">{{item.name}}<span v-if="item.clearable===false"
              style="color:red">*</span></v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="padding-0">
          <v-autocomplete item-text="name" item-value="id" class="grey lighten-2" outlined
            prepend-inner-icon="mdi-search-web" @change="editFieldname(item,item.value)" hide-details
            v-model="item.value" :items="valuePair" :clearable="item.clearable" @keydown="clearTabindex">
          </v-autocomplete>
        </v-col>
      </v-row>

       <!-- METRO adding plus -->
       <div v-if="CUSTOMER==='METRO'">
        <div v-for="(item, i) in addPlusIcon" :key="i" :value="item">
          <v-row class="mt-0">
            <v-col cols="12" sm="6" class="padding-0"
              :style="((addPlusIcon.length-1 == i))? ('max-width: 48%'):('max-width: 50%')">
              <v-btn class="btnClass" style="width:100%;cursor:default">{{reservedListData[i].name}}<span
                  v-if="item.clearable===false" style="color:red">*</span></v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="padding-0"
              :style="((addPlusIcon.length-1 == i))? ('max-width: 47%'):('max-width: 47%')">
              <v-autocomplete class="grey lighten-2" outlined prepend-inner-icon="mdi-search-web"
                @change="editFieldname(item,item.value)" hide-details v-model="item.value"
                :items="getSelectedField.standardDataMapList" :clearable="item.clearable" @keydown="clearTabindex">
              </v-autocomplete>
            </v-col>
            <v-btn  v-if="addPlusIcon.length-1 == i" @click="removeReservedList(i,item)"  style="padding-right:4px;height: 55px !important;width: 24px;min-width: 1px !important;padding: 0 10px !important;margin-top: 12px; border-top-left-radius: 0px; border-bottom-left-radius: 0px;" color="red"  dark><v-icon dark style="font-size: 17px;">mdi-close</v-icon></v-btn>

            </v-row>

        </div>
        <v-btn v-if="addPlusIcon.length<7" class="" style="width:10%;cursor:default;margin-top: 20px;"
          @click="plusIcon(addPlusIcon.length)">
          <img src="@/assets/img/ico-add.jpg" alt="Product ID input box add button" />
        </v-btn>

      </div>
    </v-card-text>
      <!--METRO END -->
      <v-card-actions class="d-flex justify-center">
          <v-btn text icon v-on="CUSTOMER==='METRO' ? { click: saveFiledMappingMetro } : { click: saveFiledMapping }"  :disabled="btnDisabledDetail" class="btn">{{
          $t('Save')
          }}</v-btn>
          <v-btn text icon @click="close" class="btn">{{
          $t('Cancel')
          }}</v-btn>
        </v-card-actions>
    </v-card>
    <!-- <msg-box :msgBox="msgBox" @closeMsgBox="closeMsgBox" /> -->
  </v-dialog>
</template>
<script>
// Utils
import EventBus from '@/plugins/eventBus'
import configs from '@/plugins/configs'

const requests = {

  saveFieldList: {
    method: 'POST',
    url: '/common/standardDataMap'
  },
  gerreservedData: {
    method: 'get',
    url: '/api/common/articles/map/reserved'
  }

}
export default {
  name: 'CustomerInfoAdd',
  props: {
    getSelectedField: [Array, Object],
    fieldMappingDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // fieldMappingDialog: false,
      btnDisabledDetail: null,
      // METRO starts
      CUSTOMER: configs.Customer,
      addPlusIcon: [],
      valuePair: [],
      reservedFile: [],
      keyPair: [],
      obj: [],
      reservedListData: [
        { name: 'Reserved Four', key: 'reservedFour', clearable: true },
        { name: 'Reserved Five', key: 'reservedFive', clearable: true },
        { name: 'Reserved Six', key: 'reservedSix', clearable: true },
        { name: 'Reserved Seven', key: 'reservedSeven', clearable: true },
        { name: 'Reserved Eight', key: 'reservedEight', clearable: true },
        { name: 'Reserved Nine', key: 'reservedNine', clearable: true },
        { name: 'Reserved Ten', key: 'reservedTen', clearable: true }
      ],
      reservedList: {},
      // METRO end
      loadAllFieldList: [],
      mappedFieldList: [
        { name: 'Store Code', key: 'mappedStationCode', clearable: false },
        { name: 'Product ID', key: 'mappedArticleId', clearable: false },
        { name: 'Product Name', key: 'mappedArticleName', clearable: true },
        { name: 'NFC', key: 'mappedNfc', clearable: true },
        { name: 'Original Price', key: 'mappedOriginPrice', clearable: true },
        { name: 'Sale Price', key: 'mappedSalePrice', clearable: true },
        { name: 'Discount Percentage', key: 'mappedDiscountPercentage', clearable: true },
        { name: 'Reserved One', key: 'mappedReservedOne', clearable: true },
        { name: 'Reserved Two', key: 'mappedReservedTwo', clearable: true },
        { name: 'Reserved Three', key: 'mappedReservedThree', clearable: true }
      ],
      mappedFieldListMetro: [
        { name: 'Store Code', key: 'mappedStationCode', clearable: false },
        { name: 'Product ID', key: 'mappedArticleId', clearable: false },
        { name: 'Product Name', key: 'mappedArticleName', clearable: true },
        { name: 'NFC', key: 'mappedNfc', clearable: true },
        { name: 'Original Price', key: 'mappedOriginPrice', clearable: true },
        { name: 'Sale Price', key: 'mappedSalePrice', clearable: true },
        { name: 'Discount Percentage', key: 'mappedDiscountPercentage', clearable: true },
        { name: 'Reserved One', key: 'reservedOne', clearable: true },
        { name: 'Reserved Two', key: 'reservedTwo', clearable: true },
        { name: 'Reserved Three', key: 'reservedThree', clearable: true }

      ]

    }
  },
  mounted () {
    this.$store.dispatch('auth/getDisabledBtn', '9500').then(flag => {
      this.btnDisabledDetail = flag
    })
    this.valuePair = this.getSelectedField.standardDataMapList.map((obj) => {
      return {
        id: obj,
        name: obj
      }
    })

    if (configs.Customer !== 'METRO') {
      this.mappedFieldList[0].value = this.getSelectedField.mappedStationCode
      this.mappedFieldList[1].value = this.getSelectedField.mappedArticleId
      this.mappedFieldList[2].value = this.getSelectedField.mappedArticleName
      this.mappedFieldList[3].value = this.getSelectedField.mappedNfc
      this.mappedFieldList[4].value = this.getSelectedField.mappedOriginPrice
      this.mappedFieldList[5].value = this.getSelectedField.mappedSalePrice
      this.mappedFieldList[6].value = this.getSelectedField.mappedDiscountPercentage
      this.mappedFieldList[7].value = this.getSelectedField.mappedReservedOne
      this.mappedFieldList[8].value = this.getSelectedField.mappedReservedTwo
      this.mappedFieldList[9].value = this.getSelectedField.mappedReservedThree
    }

    // Metro
    // if (configs.Customer === 'METRO') {
    //   this.reservedList = this.getSelectedField.reservedList
    //   this.mappedFieldList[7].value = this.getSelectedField.reservedList.reservedOne
    //   this.mappedFieldList[8].value = this.getSelectedField.reservedList.reservedTwo
    //   this.mappedFieldList[9].value = this.getSelectedField.reservedList.reservedThree

    //   if (this.getSelectedField.reservedList) {
    //     const keyList = Object.keys(this.getSelectedField.reservedList).filter((obj) => obj !== 'reservedOne' &&
    //   obj !== 'reservedTwo' && obj !== 'reservedThree')
    //     this.addPlusIcon = []
    //     for (var i = 0; i < keyList.length; i++) {
    //       var data = this.reservedListData.find((obj) => obj.key === keyList[i])
    //       if (data !== undefined) {
    //         data.value = this.getSelectedField.reservedList[keyList[i]]
    //         this.addPlusIcon.push(data)
    //       }
    //     }
    //   }
    // }
    if (configs.Customer === 'METRO') {
      this.mappedFieldListMetro[0].value = this.getSelectedField.mappedStationCode
      this.mappedFieldListMetro[1].value = this.getSelectedField.mappedArticleId
      this.mappedFieldListMetro[2].value = this.getSelectedField.mappedArticleName
      this.mappedFieldListMetro[3].value = this.getSelectedField.mappedNfc
      this.mappedFieldListMetro[4].value = this.getSelectedField.mappedOriginPrice
      this.mappedFieldListMetro[5].value = this.getSelectedField.mappedSalePrice
      this.mappedFieldListMetro[6].value = this.getSelectedField.mappedDiscountPercentage
      if (this.getSelectedField.reservedList) {
        this.reservedList = this.getSelectedField.reservedList
        this.mappedFieldListMetro[7].value = this.getSelectedField.reservedList.reservedOne
        this.mappedFieldListMetro[8].value = this.getSelectedField.reservedList.reservedTwo
        this.mappedFieldListMetro[9].value = this.getSelectedField.reservedList.reservedThree

        const reserveArray = ['reservedFour', 'reservedFive', 'reservedSix', 'reservedSeven', 'reservedEight', 'reservedNine', 'reservedTen']
        if (this.getSelectedField.reservedList) {
          const keyList = Object.keys(this.getSelectedField.reservedList).filter((obj) => obj !== 'reservedOne' &&
      obj !== 'reservedTwo' && obj !== 'reservedThree')
          this.addPlusIcon = []
          reserveArray.map((item) => {
            for (var i = 0; i < keyList.length; i++) {
              var data = this.reservedListData.find((obj) => obj.key === keyList[i])
              if (data !== undefined) {
                data.value = this.getSelectedField.reservedList[keyList[i]]
                console.log('keyyyyyyy', data, keyList[i])
                if (item === data.key) {
                  this.addPlusIcon.push(data)
                }
              }
            }
          })
        }
      } else {
        this.mappedFieldListMetro[7].value = ''
        this.mappedFieldListMetro[8].value = ''
        this.mappedFieldListMetro[9].value = ''
      }
    }
    // End
  },
  computed: {
    tableHeaders () {
      return [
        {
          text: this.$t('Mapped filed'),
          sortable: false,
          align: 'center',
          value: 'id'
        },
        {
          text: this.$t('Selected filed'),
          sortable: false,
          align: 'center',
          value: 'itemname'
        }
      ]
    },

    getInitialData () {
      // this.mappedFieldList.forEach((element, i) => {
      //   this.mappedFieldList[i].value = this.getSelectedField[element.key]
      // })
      console.log(this.mappedFieldList)
      if (configs.Customer === 'METRO') {
        return this.mappedFieldListMetro
      } else {
        return this.mappedFieldList
      }
    }
  },
  methods: {
    // METRO
    close () {
      this.$emit('close-popup')
    },
    removeReservedList (i, item) {
      this.addPlusIcon.splice(i, 1)
      this.reservedListData.splice(i, 1)
      delete this.reservedList[item.key]
      this.reservedListData = [
        { name: 'Reserved Four', key: 'reservedFour', clearable: true },
        { name: 'Reserved Five', key: 'reservedFive', clearable: true },
        { name: 'Reserved Six', key: 'reservedSix', clearable: true },
        { name: 'Reserved Seven', key: 'reservedSeven', clearable: true },
        { name: 'Reserved Eight', key: 'reservedEight', clearable: true },
        { name: 'Reserved Nine', key: 'reservedNine', clearable: true },
        { name: 'Reserved Ten', key: 'reservedTen', clearable: true }
      ]
    },
    plusIcon (i) {
      this.addPlusIcon.push(this.reservedListData[this.addPlusIcon.length])
    },
    // end
    clearTabindex () {
      var elements = document.getElementsByClassName('v-icon')
      for (let i = 0; i < elements.length; i++) {
        elements[i].setAttribute('tabindex', '-1')
      }
    },
    editFieldname (item, value) {
      const reservedFile = value
      const key = item.key
      this.reservedList[key] = reservedFile
      if (value !== null && value !== 'null' && value !== undefined && value !== 'undefined') {
        if (key !== 'reservedOne' && key !== 'reservedTwo' && key !== 'reservedThree' && key !== 'reservedFour' && key !== 'reservedFive' && key !== 'reservedSix' && key !== 'reservedSeven' && key !== 'reservedEight' && key !== 'reservedNine' && key !== 'reservedTen') {
          this.getSelectedField[item.key] = value
        } else {
          this.reserveData()
        }
      } else {
        this.getSelectedField[item.key] = value = ''
      }
    },
    // METRO func
    reserveData () {
      this.obj.push(this.reservedList)
      Object.assign(this.obj)
    },
    saveFiledMappingMetro () {
      console.log('metro')
      const selectedData = this.getSelectedField
      const reservedList = this.reservedList
      selectedData.reservedList = reservedList
      var config = Object.assign(selectedData)
      var url = configs.ApiUrl + requests.saveFieldList.url
      this.$utils.callAxiosWithBody(requests.saveFieldList.method, url, config).then(res => {
        // this.fieldMappingDialog = false
        this.close()
        if (res.status === 200) {
          EventBus.$emit('messageAlert', this.$t('The data has been saved successfully'))
          this.$emit('fieldMappingload', true)
          this.gerreservedData()
        } else if (res.status === 400) {
          EventBus.$emit('messageAlert', this.$t('Invalid parameter'))
        } else {
          EventBus.$emit('messageAlert', this.$t('Failed to save data.'))
        }
      })
        .catch(error => {
          EventBus.$emit('messageAlert', this.$t('Failed to save data.'))
          console.log(error)
        })
    },
    gerreservedData () {
      const config = { params: {} }
      this.$utils
        .callAxios(
          requests.gerreservedData.method,
          requests.gerreservedData.url,
          config
        )
        .then(res => {
          const data = res.data
          console.log(data)
          localStorage.setItem('reservedItems', JSON.stringify(data))
        })
    },
    // end
    saveFiledMapping () {
      console.log('nonmetro')

      const config = this.getSelectedField
      var url = configs.ApiUrl + requests.saveFieldList.url
      this.$utils.callAxiosWithBody(requests.saveFieldList.method, url, config).then(res => {
        // this.fieldMappingDialog = false
        this.close()
        if (res.status === 200) {
          EventBus.$emit('messageAlert', this.$t('The data has been saved successfully'))
          this.$emit('fieldMappingload', true)
        } else if (res.status === 400) {
          EventBus.$emit('messageAlert', this.$t('Invalid parameter'))
        } else {
          EventBus.$emit('messageAlert', this.$t('Failed to save data.'))
        }
      })
        .catch(error => {
          EventBus.$emit('messageAlert', this.$t('Failed to save data.'))
          console.log(error)
        })
    }
  }
}
</script>
<style scoped>
.btnClass{
  height: 55px !important;
}
.padding-0{
    padding-right:0;
    padding-left:0;
    padding-bottom: 0;
}
/* Scroll bar stylings */
::v-deep ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::v-deep ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  /* Handle */
  ::v-deep ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::v-deep ::-webkit-scrollbar-thumb:hover {
    background: #c1c1c1;
  }
  ::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 5px;
    }

</style>
